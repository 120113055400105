import { Icon } from '@loveholidays/design-system';
import React, { HTMLAttributes } from 'react';

import { useToggleSelected, useIsSelected } from './SelectableContext';
import { ClassNameProps } from '@ComponentProps';

export interface ToggleSelectionProps extends ClassNameProps, HTMLAttributes<HTMLElement> {
  controls: string;
  expands?: boolean;
  onClick?: (e: any) => void;
  showChevrons?: boolean;
}

export const ToggleSelection: React.FC<ToggleSelectionProps> = ({
  children,
  as: Component = 'a',
  controls,
  expands = true,
  onClick,
  showChevrons = false,
  ...props
}) => {
  const isSelected = useIsSelected(controls);
  const toggle = useToggleSelected(controls, onClick);

  return (
    <Component
      href={Component === 'a' ? `#${controls}` : null}
      aria-controls={controls}
      {...(expands
        ? {
            'aria-expanded': isSelected,
          }
        : {})}
      onClick={toggle}
      {...props}
    >
      {children}
      {showChevrons && (
        <Icon
          name={isSelected ? 'Toggles/ExpandOff' : 'Toggles/ExpandOn'}
          size="20"
          sx={{
            verticalAlign: 'middle',
            marginLeft: '3xs',
          }}
        />
      )}
    </Component>
  );
};
