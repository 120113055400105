import React from 'react';

import { HTMLDivProps, ClassNameProps } from '@ComponentProps';
import { useIsSelected } from '@UX/Selectable/SelectableContext';

interface CollapseProps extends HTMLDivProps, ClassNameProps {
  id: string;
  selected?: boolean;
  lazy?: boolean;
}

// displays nothing when aria-hidden is set and supports as prop
const Hideable: React.FC<HTMLDivProps> = ({ children, ...rest }) => (
  <div
    sx={{
      '&[aria-hidden="true"]': {
        display: 'none',
      },
    }}
    {...rest}
  >
    {children}
  </div>
);

const Collapse: React.FC<CollapseProps> = ({ children, id, selected, lazy, ...props }) => (
  <Hideable
    id={id}
    aria-hidden={!selected}
    {...props}
  >
    {lazy && !selected ? null : children}
  </Hideable>
);

export const SelectableCollapse: React.FC<Omit<CollapseProps, 'selected'>> = ({
  children,
  id,
  ...props
}) => {
  const isSelected = useIsSelected(id);

  return (
    <Collapse
      id={id}
      selected={isSelected}
      {...props}
    >
      {children}
    </Collapse>
  );
};
