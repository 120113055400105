import React, { useCallback, useState } from 'react';

import {
  SelectedContext,
  SelectableContext,
  SetSelected,
  isSelected,
} from '@UX/Selectable/SelectableContext';

type Props = {
  selection?: string;
  alwaysSelected?: boolean;
  onSelect?: (id: string | null, event: any) => void;
};

export const Selection: React.FC<Props> = (props) => {
  const { children, selection = null, alwaysSelected, onSelect: onSelectProp } = props;

  if (alwaysSelected && !selection) {
    throw new Error('Selection always expanded requires initialSelection prop to be set');
  }

  const [active, setActive] = useState(selection);
  const onSelect: SetSelected = useCallback(
    (selected, event) => {
      setActive((current) => {
        const selectedId = typeof selected === 'function' ? selected(current) : selected;

        if (isSelected(current, selectedId)) {
          return alwaysSelected ? current : null;
        }

        if (onSelectProp) {
          onSelectProp(selectedId, event);
        }

        return selectedId;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSelectProp],
  );

  React.useEffect(() => {
    setActive(selection);
  }, [selection]);

  return (
    <SelectedContext.Provider value={active}>
      <SelectableContext.Provider value={onSelect}>{children}</SelectableContext.Provider>
    </SelectedContext.Provider>
  );
};
